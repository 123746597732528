<template>
  <div>
    <Row :gutter="8" class="m-b-5">
      <i-col :xs="24" :sm="6" :md="3" :lg="3" class="m-b-5">
        <DatePicker size="small" transfer :show-week-numbers="true" type="date" v-model="query.startDate"
          style="width: 100%" placement="top-start" placeholder="账单开始时间"></DatePicker>
      </i-col>
      <i-col :xs="24" :sm="6" :md="3" :lg="3" class="m-b-5">
        <DatePicker size="small" transfer :show-week-numbers="true" type="date" v-model="query.endDate"
          style="width: 100%" placement="top-start" placeholder="账单结束时间"></DatePicker>
      </i-col>
      <i-col :xs="24" :sm="6" :md="3" :lg="6" class="m-b-5">
        <i-input
          size="small"
          v-model="query.queryString"
          clearable
          placeholder="关键字：支持合同名称、客户名称、合同编号查询"
        ></i-input>
      </i-col>
      <i-col :xs="24" :sm="6" :md="12" :lg="12" class="m-b-5">
        <Button
          size="small"
          type="primary"
          icon="ios-search"
          @click="SearchData"
          >搜索</Button
        >
        <!-- <Button
          size="small"
          type="success"
          icon="md-cloud-download"
          class="m-l-5"
          @click="handleExportExcel"
          >导出合同账单</Button
        > -->
      </i-col>
    </Row>
    <Table
      size="small"
      :columns="columnManagedata"
      :data="tableData"
      stripe
    ></Table>
    <div class="paging_style">
      <Page
        size="small"
        :total="total"
        :page-size="query.pageSize"
        show-total
        show-elevator
        :current="query.pageNumber"
        @on-change="changePage"
      ></Page>
    </div>
  </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
import { ParseDate } from '@/utils/dateFormat'
import { formatDebtStatus } from '@/utils/tagStatus'
import { downloadFileRequest } from '@/utils/download'

import { listDebt } from '@/api/invest/debt'

export default {
  data () {
    return {
      total: 0,
      query: {
        pageNumber: 1,
        pageSize: 15,
        queryString: '',
        startDate: '',
        endDate: ''
      },
      tableData: [],
      columnManagedata: [
        { title: '合同编号', key: 'code', width: 120 },
        { title: '合同名称', key: 'name' },
        { title: '客户名称', key: 'advertiserName' },
        { title: '资源名称', key: 'objectNames' },
        { title: '开始日期', key: 'startDate', width: 100 },
        { title: '结束日期', key: 'endDate', width: 100 },
        { title: '付款日期', key: 'dueDate', width: 100 },
        {
          title: '总额',
          key: 'amount',
          width: 120,
          render: (h, params) => {
            return h('strong', {
              attrs: {
                style: 'color:#44bd32'
              }
            },
            toMoney(params.row.amount))
          }
        },
        {
          title: '账单状态',
          width: 100,
          align: 'center',
          render: (h, params) => {
            return formatDebtStatus(
              h,
              params.row.status,
              params.row.statusName
            )
          }
        },
        {
          title: '操作',
          align: 'center',
          width: 100,
          render: (h, params) => {
            const detailButton = h(
              'a',
              {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.$store.commit('set_debt_debtId', params.row.debtId)
                    this.$store.commit('set_debt_pageType', 'detail')
                  }
                }
              },
              '详情'
            )

            return h('div', [detailButton])
          }
        }
      ]
    }
  },
  created () {
    this.initPageData()
  },
  methods: {
    initPageData () {
      if (this.query.startDate) {
        this.query.startDate = ParseDate(this.query.startDate)
      }

      if (this.query.endDate) {
        this.query.endDate = ParseDate(this.query.endDate)
      }

      listDebt(this.query).then((response) => {
        this.total = response.total
        this.tableData = response.records
        this.query.pageNumber = response.pages
      })
    },
    changePage (currentPage) {
      this.query.pageNumber = currentPage
      this.initPageData()
    },
    SearchData () {
      this.query.pageNumber = 1
      this.initPageData()
    },
    handleExportExcel () {
      const downloadCondition = {
        startDate: '',
        endDate: '',
        queryString: this.query.queryString
      }

      if (this.query.startDate) {
        downloadCondition.startDate = ParseDate(this.query.startDate)
      }
      if (this.query.endDate) {
        downloadCondition.endDate = ParseDate(this.query.endDate)
      }

      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-invest/v1/debt/exportContractDebtList', downloadCondition, '确认单列表.xlsx')
    }
  }
}
</script>
