import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 查询账单列表
 * @param {Object} data
 * @returns
 */
export function listDebt (data) {
  return request.post('/ooh-invest/v1/debt/listDebt', qs.stringify(data))
}

/**
 * 查询对账单明细
 * @param {Object} data
 * @returns
 */
export function listDebtDetail (data) {
  return request.post('/ooh-invest/v1/debt/listDebtDetail', qs.stringify(data))
}

/**
 * 查询单个账单
 * @param {Object} data
 * @returns
 */
export function getDebt (data) {
  return request.post('/ooh-invest/v1/debt/getDebt', qs.stringify(data))
}

/**
 * 调整账单明细的金额
 * @param {Object} data
 * @returns
 */
export function updateDebtDetailAmount (data) {
  return request.post('/ooh-invest/v1/debt/updateDebtDetailAmount', qs.stringify(data))
}

/**
 * 确认对账单明细
 * @param {Object} data
 * @returns
 */
export function confirmDebtDetail (data) {
  return request.post('/ooh-invest/v1/debt/confirmDebtDetail', qs.stringify(data))
}
